(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championship.assignTeams.controller:AssignTeamsCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.championships.championship.assignTeams')
    .controller('AssignTeamsCtrl', AssignTeamsCtrl);

  function AssignTeamsCtrl($filter, $stateParams, $mdToast, $state, AssignTeams, championship, sports, categories, genders, teams, towns) {
    var vm = this;
    vm.ctrlName = 'AssignTeamsCtrl';

    function buildTeams(teams) {
      var newTeams = [];
      angular.forEach(teams, function (t) {
        t.town = findItemById(towns, t.entityTownId).name;
        t.gender = findItemById(genders, t.teamGenderId).name;
        newTeams.push(t);
      });
      return newTeams;
    }

    vm.teams = buildTeams(teams);

    vm.championship = championship;
    vm.championship.sport = $filter('translate')('SPORTS.' + findItemById(sports, vm.championship.sportId).name);
    vm.championship.category = $filter('translate')('CATEGORIES.' + findItemById(categories, vm.championship.categoryId).name);
    vm.championship.gender = $filter('translate')(findItemById(genders, vm.championship.genderId).name);

    vm.assignedTeams = {
      listName: 'CHAMPIONSHIPS.ASSIGNED_TEAMS',
      items: [],
      dragging: false
    };

    vm.unassignedTeams = {
      listName: 'CHAMPIONSHIPS.TEAMS_TO_ASSIGN',
      items: [],
      dragging: false
    };

    vm.displayAll = false;
    vm.setTeamsDisplay = function () {
      var championshipId = parseInt($stateParams.championshipId);
      angular.forEach(vm.unassignedTeams.items, function (team) {
        team.display = vm.displayAll || !team.hasJoinedAChampionship || team.joinedChampionships.includes(championshipId);
      });
      angular.forEach(vm.assignedTeams.items, function (team) {
        team.display = vm.displayAll || !team.hasJoinedAChampionship || team.joinedChampionships.includes(championshipId);
      });
    };

    angular.forEach(vm.teams, function (team) {
      var championshipId = parseInt($stateParams.championshipId);
      if (team.joinedChampionships.includes(championshipId)) {
        vm.assignedTeams.items.push(team);
      } else {
        vm.unassignedTeams.items.push(team);
      }
    });

    vm.setTeamsDisplay();

    vm.teamsLists = [
      vm.unassignedTeams,
      vm.assignedTeams
    ];

    vm.getSelectedItemsIncluding = function (list, item) {
      item.selected = true;
      return list.items.filter(function (item) {
        return item.selected;
      });
    };

    vm.onDragStart = function (list, event) {
      list.dragging = true;
      if (event.dataTransfer.setDragImage) {
        var img = new Image();
        img.src = 'images/ic_content_copy_black_48px.svg';
        event.dataTransfer.setDragImage(img, 0, 0);
      }
    };

    vm.onDrop = function (list, items, index) {
      angular.forEach(items, function (item) {
        item.selected = false;
      });
      list.items = list.items.slice(0, index)
        .concat(items)
        .concat(list.items.slice(index));
      return true;
    };

    vm.onMoved = function (list) {
      list.items = list.items.filter(function (item) {
        return !item.selected;
      });
    };

    vm.updateAssignedTeams = function () {
      var assignedArray = [];
      angular.forEach(vm.assignedTeams.items, function (team) {
        assignedArray.push(team.teamId);
      });

      AssignTeams.update({championshipId: $stateParams.championshipId}, assignedArray, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CHAMPIONSHIPS.SUCCESSFUL_TEAMS_UPDATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.go('home.championships.championship', {championshipId: vm.championship.id});
      }, function (err) {
        var message = err.data || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    };

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
